import useScreenSize from "../../hooks/useScreenSize";
import breakpoints from "../../theme/antdesign";
import colors from "../../theme/thiana";
import Visual from "../../assets/logo/Visual.png";
import ThianaLogo from "../../assets/logo/LOGO_THIANA.svg";
import { Flex } from "antd";

interface Props {
  children: JSX.Element;
}
export default function Layout(props: Props) {
  let { currentScreenSize } = useScreenSize();
  return (
    <Flex
      flex={1}
      style={{
        alignItems: "start",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      {currentScreenSize <= breakpoints.md ? null : (
        <Flex
          flex={1}
          vertical
          style={{
            backgroundColor: colors.thiana.grey[100],
            alignItems: "center",
            justifyContent: "center",
            gap: 20,
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              backgroundImage: `url(${Visual})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div
              style={{
                marginTop: 20,
                marginLeft: 50,
                gap: 10,
              }}
            >
              <img
                src={ThianaLogo}
                alt="logo"
                width={"100px"}
                style={{ width: 100 }}
              ></img>
              <div style={{ color: "white", fontSize: 14 }}>
                L'intelligence artificielle au service des médecins
              </div>
            </div>
          </div>
        </Flex>
      )}
      <Flex
        flex={1}
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        {props.children}
      </Flex>
    </Flex>
  );
}
