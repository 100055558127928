import { useNavigate } from "react-router-dom";
import {
  isActivate,
  isProfessionalCreated,
  redirectToThianaAssistantWithAccessToken,
  redirectToThianaDPIWithTokens,
} from "../../../utils/security";
import { useEffect, useState } from "react";
import { Button, Tooltip } from "antd";
import colors from "../../../theme/thiana";
import Title from "antd/es/typography/Title";
import useScreenSize from "../../../hooks/useScreenSize";
import breakpoints from "../../../theme/antdesign";
import thiana_assistant_logo from "../../../assets/logo/thiana_assistant_logo.svg";
import thiana_dpi_logo from "../../../assets/logo/thiana_dpi_logo.svg";

const cardStyle: React.CSSProperties = {
  width: 312,
  display: "flex",
  gap: 8,
  alignItems: "center",
  flexDirection: "column",
  cursor: "pointer",
  borderRadius: 16,
  padding: 32,
  boxShadow: "0px 2px 4px -2px rgba(24, 24, 28, 0.06)",
  transition: "transform 0.2s, box-shadow 0.2s",
  backgroundColor: "white",
};

export function RedirectionController() {
  let navigate = useNavigate();
  const [isAssistantHovered, setIsAssistantHovered] = useState(false);
  const [isDPIHovered, setIsDPIHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const { currentScreenSize } = useScreenSize();

  useEffect(() => {
    if (!isActivate()) navigate("/activate");
    else if (!isProfessionalCreated()) navigate("/informations");
  }, [navigate]);

  const redirectToThianaDPI = () => {
    let accessToken = localStorage.getItem("accessJWT");
    if (accessToken) redirectToThianaDPIWithTokens(accessToken);
  };

  const redirectToThianaAssistant = () => {
    let accessToken = localStorage.getItem("accessJWT");
    if (accessToken) redirectToThianaAssistantWithAccessToken(accessToken);
  };

  const cardStyleHover: React.CSSProperties = {
    ...cardStyle,
    transform: "scale(1.05)",
    boxShadow: "0px 4px 8px -4px rgba(24, 24, 28, 0.06)",
    backgroundColor: isClicked
      ? colors.thiana.grey[500]
      : colors.thiana.grey[300],
  };

  const handleCardClick = (mouseClic: boolean) => {
    setIsClicked(mouseClic);
  };

  const handleAssistantCardLeave = () => {
    if (isClicked) setIsClicked(false);
    setIsAssistantHovered(false);
  };

  const handleDPICardLeave = () => {
    if (isClicked) setIsClicked(false);
    setIsDPIHovered(false);
  };

  return (
    <div style={{ background: "#F5F5F5" }}>
      <div
        style={{
          minHeight: "100vh",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 15,
        }}
      >
        {process.env.REACT_APP_CURRENT_ENV === "PREVIEW" ? (
          <div
            style={{
              position: "fixed",
              top: 10,
              left: 10,
              color: "red",
              fontSize: 14,
            }}
          >
            Version de test
          </div>
        ) : process.env.REACT_APP_CURRENT_ENV === "DEV" ? (
          <div
            style={{
              position: "fixed",
              top: 10,
              left: 10,
              color: "green",
              fontSize: 14,
            }}
          >
            Environnement de développement
          </div>
        ) : null}

        <Title
          level={1}
          style={{
            fontWeight: 600,
            fontSize: 36,
            margin: 0,
            textAlign: "center",
          }}
        >
          Choisissez votre application
        </Title>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection:
              currentScreenSize < breakpoints.sm ? "column" : "row",
            margin: 10,
            gap: 40,
            marginBottom: 20,
          }}
        >
          {/* Thiana Assistant */}
          <div
            style={isAssistantHovered ? cardStyleHover : cardStyle}
            onMouseEnter={() => setIsAssistantHovered(true)}
            onMouseDown={() => handleCardClick(true)}
            onMouseUp={() => handleCardClick(false)}
            onMouseLeave={handleAssistantCardLeave}
            onClick={redirectToThianaAssistant}
            data-cy="action-to-assistant"
          >
            <img src={thiana_assistant_logo} alt="Logo Thiana Doc" />

            <div style={{ textAlign: "center", fontSize: 14 }}>
              Génération automatique de vos observations médicale à partir de
              langage naturel.
            </div>
          </div>

          {process.env.REACT_APP_CURRENT_ENV !== "PROD" ? (
            <div
              style={isDPIHovered ? cardStyleHover : cardStyle}
              onMouseEnter={() => setIsDPIHovered(true)}
              onMouseDown={() => handleCardClick(true)}
              onMouseUp={() => handleCardClick(false)}
              onMouseLeave={handleDPICardLeave}
              onClick={redirectToThianaDPI}
            >
              <img src={thiana_dpi_logo} alt="Logo Thiana DPI" />

              <div style={{ textAlign: "center", fontSize: 14 }}>
                Logiciel de gestion de dossier patient complet pour médecin
                libéraux
              </div>
            </div>
          ) : (
            <Tooltip
              title="Application en cours de développement ..."
              placement="right"
            >
              <div style={cardStyle}>
                <img src={thiana_dpi_logo} alt="Logo Thiana DPI" />

                <div style={{ textAlign: "center", fontSize: 14 }}>
                  Logiciel de gestion de dossier patient complet pour médecin
                  libéraux
                </div>
              </div>
            </Tooltip>
          )}
        </div>
        <Button
          type="text"
          onClick={() => navigate("/logout")}
          style={{ color: colors.thiana.grey[500] }}
        >
          Déconnexion
        </Button>
      </div>
    </div>
  );
}
